@keyframes pulseSvg {
  0% {
    fill: var(--color-light);
  }
  50% {
    fill: var(--color-primary);
  }
  100% {
    fill: var(--color-light);
  }
}

@keyframes pulseColor {
  0% {
    background-color: var(--color-light);
  }
  50% {
    background-color: var(--color-grey);
  }
  100% {
    background-color: var(--color-light);
  }
}

@keyframes pulse-white {
  0% {
    /* transform: scale(0.95); */
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  50% {
    /* transform: scale(1); */
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    /* transform: scale(0.95); */
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
