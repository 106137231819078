@font-face {
  font-family: "sf-compact";
  src: url("../fonts/sf-compact-text-regular-webfont.woff2") format("woff2"),
    url("../fonts/sf-compact-text-regular-webfont.woff") format("woff"),
    url("../fonts/sf-compact-text-regular-webfont.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "sf-compact";
  src: url("../fonts/sf-compact-text-semibold-webfont.woff2") format("woff2"),
    url("../fonts/sf-compact-text-semibold-webfont.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "samsungOne";
  src: url("../fonts/samsungOne/SamsungOne-400.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "samsungOne";
  src: url("../fonts/samsungOne/SamsungOne-700.woff") format("woff");
  font-weight: 700;
}

 

@font-face {
  font-family: "samsung";
  src:url("../fonts/samsunginterface-regular.woff") format("woff");
 
  font-weight: 500;
  font-style: normal;
}

html {
  /* This defines what 1rem is */
  font-size: 62.5%; /*1rem = 10px (10/16 = 62.5%) */
}

body {
  font-family: "sf-compact", sans-serif;
  font-size: var(--size-default);
  color: var(--color-dark);
}

*,
*::after,
*::before {
  font-weight: normal;
}

h1,
h2,
h3 {
  font-weight: 700;
}

* {
  outline-color: var(--color-secondary);
}
