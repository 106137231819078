:host,
:root {
  /* Primary Colors */
  --color-primary: #00a79e;
  --color-secondary: #8dc53e;
  --color-dark: #333333;
  --color-light: #ffffff;

  /* Supporting Colors */
  --color-primary-light: #24bcb4;
  --color-primary-dark: #00867f;
  --color-primary-darker: #00534f;
  --color-secondary-light: #050505;
  --color-secondary-dark: #6a942f;
  --color-border: #e4e4e4;
  --color-background: #ffffff;
  --color-vector: #fdd118;
  --color-grey: #dadada;
  --color-grey-light: #efefefef;
  --color-grey-dark: #8d8d8d;
  --color-darker: #2a3142;

  /* Text Styles for Label and Values */
  /* Enabled State */
  --color-text-label: #666666;
  --color-text-value: #333333;
  /* Disabled State */
  --color-text-label-disabled: #e5e6e7;
  --color-text-value-disabled: #d5d6d7;
  /* Muted Text */
  --color-text-muted: #6c757d;

  /* Indication Colors */
  --color-success: #30d755;
  --color-error: #ff5353;
  --color-warning: #ff8500;

  /* Size */
  --size-default: 1.6rem;
  --size: 16px;

  /* Border Radius */
  --border-radius-general: 5px;

  /* Shadows */
  --shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

:root {
  --wrapper-max-width: fit-content;
  --content-margin: 0 0;
  --mobile-margin: 0;
}
@media screen and (min-width: 320px) {
  :root {
    --wrapper-max-width: fit-content;
    --content-margin: 0 1rem;
    --mobile-margin: 2rem;
  }
}
@media screen and (min-width: 480px) {
  :root {
    --wrapper-max-width: 42rem;
    --content-margin: 0 1.5rem;
  }
}
@media screen and (min-width: 600px) {
  :root {
    --wrapper-max-width: 54rem;
    --content-margin: 0 2rem;
  }
}
@media screen and (min-width: 768px) {
  :root {
    --wrapper-max-width: 70rem;
    --content-margin: 0 3rem;
  }
}
@media screen and (min-width: 900px) {
  :root {
    --wrapper-max-width: 82rem;
    --content-margin: 0 4rem;
  }
}
@media screen and (min-width: 1024px) {
  :root {
    --wrapper-max-width: 96rem;
    --content-margin: 0 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --wrapper-max-width: 114rem;
    --content-margin: 0 4.8rem;
  }
}
