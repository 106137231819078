/* Background Colors */
.bg-light {
  background-color: var(--color-light);
}
.bg-grey-light {
  background-color: var(--color-grey-light);
}
.bg-grey-dark {
  background-color: var(--color-grey-dark);
}
.bg-violet-50 {
  background-color: rgb(245 243 255);
}
.hover\:bg-light:hover {
  background-color: var(--color-light);
}
.hover\:bg-grey-light:hover {
  background-color: var(--color-grey-light);
}
.hover\:bg-primary-dark:hover {
  background-color: var(--color-primary-dark);
}
.active\:bg-primary-darker:active {
  background-color: var(--color-primary-darker);
}
.bg-overlay {
  background: #00000066;
}
.bg-primary {
  background-color: var(--color-primary);
}
.bg-transparent {
  background: transparent;
}

/* Borders */
.border {
  border: 1px solid var(--color-border);
}
.border-2 {
  border: 2px solid var(--color-border);
}
.border-t {
  border-top-width: 1px;
}
.border-0 {
  border: none;
}
.border-slate-300 {
  border-color: rgba(0, 0, 0, 0.1);
}
.border-r {
  border-right: 1px solid;
}

.circle {
  border-radius: 50%;
}
/* Border Color */
.border-primary {
  border-color: var(--color-primary);
}
.border-dark {
  border-color: var(--color-dark);
}
/* Border Radius */
.rounded-1 {
  border-radius: 3px;
}
.rounded-tbl-1 {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.rounded-tbr-1 {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.rounded-2 {
  border-radius: 10px;
}
.rounded-3 {
  border-radius: 6px;
}
.rounded-bl-2 {
  border-bottom-left-radius: 10px;
}
.rounded-l-5 {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.rounded-full {
  border-radius: 9999px;
}
/* Border Style */
.border-solid {
  border-style: solid;
}
/* Border Width */
.border-b-4 {
  border-bottom-width: 4px;
}

/* Display */
.block {
  display: block;
}
.inline {
  display: inline;
}
.hidden {
  display: none;
}

/* Flexbox */
.flex {
  display: flex;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}

.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-around {
  justify-content: space-around;
}
.justify-between {
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
@media (max-width: 640px) {
  .md\:flex-col {
    flex-direction: column;
  }
  .md\:items-center {
    align-items: center;
  }
}
.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

/* Floats */
.float-left {
  float: left;
}

/* Grid */
.grid {
  display: grid;
}
.grid-auto-fill-125-0 {
  grid-template-columns: repeat(auto-fill, minmax(125px, 0));
}
.grid-auto-fill-300 {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-auto-columns-1 {
  grid-auto-columns: 1fr;
}
.grid-flow-col {
  grid-auto-flow: column;
}
.gap-4 {
  gap: calc(var(--size) * 1.5);
}
.gap-x-3 {
  column-gap: calc(var(--size) * 1);
}

/* Line Height */
.leading-normal {
  line-height: normal;
}
/* Lists */
.list-space:not(:last-child) {
  margin-right: 8px;
}

/* Opacity */
.opacity-50 {
  opacity: 0.5;
}
.hover\:opacity-50:hover {
  opacity: 0.5;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.no-wrap {
  white-space: nowrap;
}
.pre-line {
  white-space: pre-line;
}
.pre-wrap {
  white-space: pre-wrap;
}
.object-cover {
  object-fit: cover;
}
.object-contain {
  object-fit: contain;
}

/* Pointer */
.cursor-pointer {
  cursor: pointer;
}

/* Positioning */
.pos-abs {
  position: absolute;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}

/* Sizing */
.h-auto {
  height: auto;
}
.h-16 {
  height: 4rem;
}
.h-80 {
  height: 80%;
}
.h-100 {
  height: 100%;
}
.h-60vh {
  height: 60vh;
}
.h-50p {
  height: 50px;
}
.h-100p {
  height: 100px;
}
.h-200p {
  height: 200px;
}
.h-min {
  height: min-content;
}
.w-16 {
  width: 4rem;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}

.w-45 {
  width: 40%;
}
.w-65 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100%;
}
.w-50p {
  width: 50px;
}
.w-100p {
  width: 100px;
}
.w-200p {
  width: 200px;
}
.w-300p {
  width: 300px;
}
.w-350 {
  width: 350px;
}
.w-600p {
  width: 600px;
}
.w-max {
  width: max-content;
}
.w-min {
  width: min-content;
}
.w-fit {
  width: fit-content;
}
.w-full {
  width: 100%;
}
.box-60 {
  height: 60px;
  width: 60px;
}
.min-h-75 {
  min-height: 75px;
}
.min-h-100 {
  min-height: 97px;
}
.min-w-136 {
  min-width: 136px;
}
.max-w-screen-90 {
  max-width: 90vw;
}

/* Spacing */
.m-1 {
  margin: calc(var(--size) * 0.25);
}
.m-2 {
  margin: calc(var(--size) * 0.5);
}
.m-3 {
  margin: calc(var(--size) * 1);
}
.m-auto {
  margin: auto;
}
.mt-1\/4 {
  margin-top: calc(var(--size) * 0.125);
}
.mt-2 {
  margin-top: calc(var(--size) * 0.5);
}
.mt-3 {
  margin-top: calc(var(--size) * 1);
}
.mt-4 {
  margin-top: calc(var(--size) * 1.5);
}
.mt-6 {
  margin-top: calc(var(--size) * 4);
}
.mt-auto {
  margin-top: auto;
}
.mr-1\/4 {
  margin-right: calc(var(--size) * 0.125);
}
.mr-1 {
  margin-right: calc(var(--size) * 0.5);
}
.mr-3 {
  margin-right: calc(var(--size) * 1);
}
.mr-auto {
  margin-right: auto;
}

.mb-2 {
  margin-bottom: calc(var(--size) * 0.5);
}
.mb-3 {
  margin-bottom: calc(var(--size) * 1);
}
.mb-4 {
  margin-bottom: calc(var(--size) * 1.5);
}
.ml-1 {
  margin-left: calc(var(--size) * 0.25);
}
.ml-2 {
  margin-left: calc(var(--size) * 0.5);
}
.ml-3 {
  margin-left: calc(var(--size) * 1);
}
.ml-auto {
  margin-left: auto;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-1 {
  margin-left: calc(var(--size) * 0.5);
  margin-right: calc(var(--size) * 0.5);
}
.mx-2 {
  margin-left: calc(var(--size) * 1);
  margin-right: calc(var(--size) * 1);
}
.mx-4 {
  margin-left: calc(var(--size) * 1.5);
  margin-right: calc(var(--size) * 1.5);
}
.mx-6 {
  margin-left: calc(var(--size) * 4);
  margin-right: calc(var(--size) * 4);
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-2 {
  margin-top: calc(var(--size) * 0.5);
  margin-bottom: calc(var(--size) * 0.5);
}
.my-4 {
  margin-top: calc(var(--size) * 1.5);
  margin-bottom: calc(var(--size) * 1.5);
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.my-100p {
  margin-top: 100px;
  margin-bottom: 100px;
}

.p-0 {
  padding: 0;
}
.p-1 {
  padding: calc(var(--size) * 0.25);
}
.p-2 {
  padding: calc(var(--size) * 0.5);
}
.p-3 {
  padding: calc(var(--size));
}
.p-4 {
  padding: calc(var(--size) * 1.5);
}
.p-5 {
  padding: calc(var(--size) * 2);
}
.p-6 {
  padding: calc(var(--size) * 2.5);
}
.pt-2 {
  padding-top: calc(var(--size) * 0.5);
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-7 {
  padding-top: 2.25rem;
}
.pr-2 {
  padding-right: calc(var(--size) * 0.5);
}
.pl-0 {
  padding-left: 0px;
}
.pb-0 {
  padding-bottom: calc(var(--size) * 0);
}
.pl-2 {
  padding-left: calc(var(--size) * 0.5);
}
.pb-1 {
  padding-bottom: calc(var(--size) * 0.25);
}
.pb-3 {
  padding-bottom: calc(var(--size) * 1);
}

.px-2 {
  padding-left: calc(var(--size) * 0.5);
  padding-right: calc(var(--size) * 0.5);
}
.px-3 {
  padding-left: calc(var(--size) * 1);
  padding-right: calc(var(--size) * 1);
}
.px-4 {
  padding-left: calc(var(--size) * 1.5);
  padding-right: calc(var(--size) * 1.5);
}
.px-5 {
  padding-left: calc(var(--size) * 3);
  padding-right: calc(var(--size) * 3);
}
.px-12px {
  padding-left: 12px;
  padding-right: 12px;
}

.py-1 {
  padding-top: calc(var(--size) * 0.25);
  padding-bottom: calc(var(--size) * 0.25);
}
.py-2 {
  padding-top: calc(var(--size) * 0.5);
  padding-bottom: calc(var(--size) * 0.5);
}
.py-3 {
  padding-top: calc(var(--size) * 1);
  padding-bottom: calc(var(--size) * 1);
}
.py-4 {
  padding-top: calc(var(--size) * 1.5);
  padding-bottom: calc(var(--size) * 1.5);
}
.py-6px {
  padding-top: 6px;
  padding-bottom: 6px;
}

/* Resize */
.resize-y {
  resize: vertical;
}

/* Text Alignment */
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-left {
  text-align: left;
}

/* Text Color */
.text-dark {
  color: var(--color-dark);
}
.text-error {
  color: var(--color-error);
}
.text-primary {
  color: var(--color-primary);
}
.text-light {
  color: var(--color-light);
}
.text-muted {
  color: var(--color-text-muted);
}
.text-transparent {
  color: transparent;
}
.text-inherit {
  color: inherit;
}
.text-sm {
}
.text-violet-700 {
}
.text-slate-500 {
  color: rgb(100 116 139);
}
.text-transparent {
  color: transparent;
}

/* Text Font Family */
.font-samsung {
  font-family: samsungOne;
}

/* Text Size */
.text-2 {
  font-size: calc(var(--size) * 0.5);
}
.text-2x {
  font-size: calc(var(--size) * 0.75);
}
.text-3 {
  font-size: calc(var(--size) * 1);
}
.text-3-25 {
  font-size: calc(var(--size) * 1) 0.25;
}
.text-4 {
  font-size: calc(var(--size) * 1.5);
}
.text-5 {
  font-size: calc(var(--size) * 2);
}
.text-6 {
  font-size: calc(var(--size) * 2.5);
}
.text-7 {
  font-size: calc(var(--size) * 4);
  font-weight: 900;
}
.text-12px {
  font-size: 12px;
}
.text-14px {
  font-size: 14px;
}
.text-18px {
  font-size: 18px;
}
.text-20px {
  font-size: 20px;
}
/* Text Weight */
.font-weight-bold {
  font-weight: bold;
}
.font-weight-normal {
  font-weight: normal;
}

/* Top / Right / Bottom / Left */
.-top-0 {
  top: 0px;
}
.-right-0 {
  right: 0px;
}

/* Transition */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Transition Duration */
.duration-150 {
  transition-duration: 150ms;
}

/* Z-Index */
.-z-1 {
  z-index: -1;
}
.z-1 {
  z-index: 1;
}
.z-20 {
  z-index: 20;
}

/* ****************************NON ATOMICS**************************** */
.pos-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.na-story-card {
  width: 342px;
  max-width: calc(100% - 1rem);
  min-height: 355px;
}
.na-story-card-employee {
  width: 342px;
  max-width: calc(100% - 1rem);
  min-height: 415px;
}
.na-story-card-image {
  max-height: 184px;
}
.na-story-card-description {
  max-width: 38ch;
}
.na-modal {
  min-width: 300px;
  width: 400px;
}
.na-full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.evidence-tab-border-bottom {
  border-bottom: 4px solid var(--color-primary);
}
.border-bottom {
  border-bottom: 1px solid var(--color-border);
}
.color-picker {
  border: none;
  border-right: 1px solid var(--color-border);
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.leaflet-container {
  width: 100%;
  height: 300px;
}
.markercluster-map {
  height: 90vh;
}
.h-100p {
  height: 100px;
}
.required:required:valid {
  border-color: var(--color-success);
}
.required:invalid {
  border-color: var(--color-error);
}
.overlay-bg {
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 900000;
}
.loader-cont {
  background: rgba(255, 255, 255, 0);
  width: 204px;
  height: 204px;
  line-height: 104px;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 11000000;
  border-radius: 100px;
}
.shimmer-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
}
.shimmer-overlay::after {
  z-index: 100;
  content: "";
  position: absolute;
  top: 0;
  right: -100%;
  bottom: 0;
  left: -100%;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 33.3%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 66.6%
  );
  animation: shimmer 1s infinite linear;
}
@keyframes shimmer {
  from {
    transform: translateX(-33.3%);
  }
  to {
    transform: translateX(33.3%);
  }
}
/* Toggle Button */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--color-primary);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--color-primary);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
}

.slider.round.loading:before {
  animation: pulse-white 2s infinite;
}

/* Recharts Overwrite */
.recharts-text.recharts-polar-radius-axis-tick-value {
  font-size: 12px;
  fill: var(--color-text-muted);
}
.recharts-text.recharts-polar-angle-axis-tick-value {
  font-size: 11px;
}
.recharts-layer.recharts-polar-radius-axis-tick:last-child {
  display: none;
}
.li-border-r:not(:last-child) {
  border-right: 1px solid;
}

/* Leaflet Customs */
/* .edit-options.leaflet-popup {
  bottom: initial !important;
  left: 25px !important;
}
.edit-options > .leaflet-popup-content-wrapper {
  border-radius: 3px;
}
.edit-options .leaflet-popup-tip-container {
  display: none;
} */
.edit-options .leaflet-popup-content {
  margin: 0 !important;
}
.edit-options .leaflet-popup-close-button {
  display: none !important;
}
.story-card-popup .leaflet-popup-content {
  margin: 0px !important;
  max-width: 100% !important;

}
.story-card-popup {
  width: 280px;
}
.space-x-6 {
  margin-left: 1.5rem;
}
.shrink-0 {
  flex-shrink: 0;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.leaflet-container {
  height: 100% !important;
}
/* edit by fariborz  from bottom: 5px; to bottom :-3px*/
#login_modal input[type="radio"]:checked:after {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  bottom: -3px;
  left: 1px;
  position: relative;
  background-color: #00a79e;
  content: "";
  display: inline-block;
  visibility: visible;
}
input[type="radio"]:checked:after {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  bottom: 5px;
  left: 1px;
  position: relative;
  background-color: #00a79e;
  content: "";
  display: inline-block;
  visibility: visible;
}
